let config = {
	"localApi": "https://restapi.amap.com/v3/ip?parameters",
	"amapKey": "e578fe7778da38ca76a199496a7e480a",
	"provinceList": {
		"河北省": "hebei",
		"山西省": "shaanxi",
		"辽宁省": "liaoning",
		"吉林省": "jilin",
		"江苏省": "jiangsu",
		"浙江省": "zhejiang",
		"安徽省": "anhui",
		"福建省": "fujian",
		"江西省": "jiangxi",
		"山东省": "shandong",
		"河南省": "henan",
		"湖北省": "hubei",
		"湖南省": "hunan",
		"广东省": "guangdong",
		"海南省": "hainan",
		"四川省": "sichuan",
		"贵州省": "guizhou",
		"云南省": "yunnan",
		"陕西省": "shanxi",
		"内蒙古自治区": "neimenggu",
		"广西省": "guangxi",
		"新疆维吾尔自治区": "xinjiang",
		"北京市": "beijing",
		"天津市": "tianjin",
		"上海市": "shanghai",
	},
}

export {config}